* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "montserrat", "SF Pro Display", "SF Pro Icons", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  -webkit-text-size-adjust: none;
}

body {
  background-color: #f8f8f8;
}

a {
  text-decoration: none;
}

li {
  display: block;
}

// @font-face {
//   font-family: "montserrat";
//   src: url("/fonts/Montserrat/Montserrat-Medium.ttf");
//   font-weight: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "montserrat";
//   src: url("/fonts/Montserrat/Montserrat-ExtraBold.ttf");
//   font-weight: bold;
//   font-display: swap;
// }
