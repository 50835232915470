@import "./vars";
@import "./shared";

.download {
  text-align: center;

  .container {
    max-width: 480px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .header {
    width: 100%;
    height: 44px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .main {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo {
    width: 131px;
    height: 17px;
  }

  .text {
    padding-bottom: 48px;
  }

  .heading {
    margin: 10px 0;
    font-size: 24px;
    font-weight: 800;
    color: #232323;
  }

  .desc {
    font-size: 15px;
    color: #999;
  }

  .hero {
    width: 72%;
  }

  .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 60px;
  }

  .button {
    $height: 49px;

    @include button-accent();

    width: 100%;
    height: $height;
    justify-content: center;
    font-size: 15px;
  }
}
