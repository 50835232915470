@import "./vars";

%button {
  $height: 36px;

  height: $height;
  display: flex;
  align-items: center;
  padding: 0 28px;
  margin: 0 10px;
  border-radius: 12px;
  font-size: 14px;
  appearance: none;
  border-style: none;
}

%button-accent {
  @extend %button;

  background-color: $color-accent;
  color: #fff;
}

%button-ghost {
  @extend %button;

  border: 1px solid $color-accent;
  color: $color-accent;
  transition: border-color 0.6s, color 0.6s;

  &:hover {
    border-color: lighten($color-accent, 10);
    color: lighten($color-accent, 10);
  }
}

@mixin button() {
  $height: 36px;

  height: $height;
  display: flex;
  align-items: center;
  padding: 0 28px;
  margin: 0 10px;
  border-radius: 12px;
  font-size: 14px;
  appearance: none;
  border-style: none;
}

@mixin button-accent() {
  @include button();

  background-color: $color-accent;
  color: #fff;
}

@mixin button-ghost() {
  @include button();

  border: 1px solid $color-accent;
  color: $color-accent;
  transition: border-color 0.6s, color 0.6s;

  &:hover {
    border-color: lighten($color-accent, 10);
    color: lighten($color-accent, 10);
  }
}
